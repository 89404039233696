@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: PlusJakartaSans-Bold;
  src: url(./fonts/PlusJakartaSans-Bold.ttf);
}
@font-face {
  font-family: PlusJakartaSans-SemiBold;
  src: url(./fonts/PlusJakartaSans-SemiBold.ttf);
}
@font-face {
  font-family: PlusJakartaSans-Regular;
  src: url(./fonts/PlusJakartaSans-Regular.ttf);
}
@font-face {
  font-family: PlusJakartaSans-Medium;
  src: url(./fonts/PlusJakartaSans-Medium.ttf);
}
body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
